// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('stylesheets/application.scss')

require("@rails/ujs").start()
require('./search')

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// import "cal-sans";
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


document.addEventListener("DOMContentLoaded", (e) => {
  let chrome_cta = document.getElementById('chrome-cta');
  let is_chrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;

  if(!is_chrome && !!chrome_cta){
    chrome_cta.remove();
  }

  let pulse_button = document.getElementById('js-pulse');

  if(pulse_button){
    pulse_button.addEventListener('click', (e) => {
      gtag('event', 'pulse', { 'event_category' : e.target.getAttribute('data-service') });
      plausible('Pulse', {props: { service: e.target.getAttribute('data-service') }});
    });
  }

  let notifications = document.getElementById('notifications-animation');

  if(notifications){
    setInterval(() => {
      let lastNotification = notifications.lastElementChild;

      notifications.insertBefore(lastNotification, notifications.firstElementChild )
    }, 5000);
  }

  let show_components_button = document.getElementById('js-show-components');

  if(show_components_button){
    show_components_button.addEventListener('click', (e) => {
      let components = document.getElementById('hidden_components');
      components.classList.remove('hidden');
      show_components_button.classList.add('hidden');
      e.preventDefault();
    });
  }

  let main_header = document.getElementById('main-header');
  if(main_header){
    document.addEventListener('scroll', function(e) {
      if(window.scrollY > 0){
        main_header.classList.add('header-scrolled');
      } else {
        main_header.classList.remove('header-scrolled');
      }
    });
  }

  let steps = document.getElementById('welcome-steps');
  if(steps){
    // get links and add on click event
    let links = steps.querySelectorAll('a.step');

    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        let tagName = 'a'
        let el = e.target;

        while (el && el.parentNode) {
          if (el.tagName && el.tagName.toLowerCase() == tagName) {
            break;
          }
          el = el.parentNode;
        }

        let target = el.getAttribute('data-target');

        let target_element = document.getElementById(target);
        // hide all steps and show the target
        steps.querySelectorAll('.wstep').forEach((step) => {
          step.classList.add('hidden');
        });

        steps.querySelectorAll('.step').forEach((step) => {
          step.classList.remove('step-active');
        });

        el.classList.add('step-active');
        target_element.classList.remove('hidden');
        e.preventDefault();
      });
    });
  }

  let search_components = document.getElementById('search_components');
  let timeoutId;
  // let search_loading = document.getElementById('search_loading');

  if(search_components){
    search_components.addEventListener('keyup', (e) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        let components = document.getElementById('component_container').querySelectorAll('.component');
        // search_loading.classList.remove('hidden');

        components.forEach((component) => {
          let name = component.innerText;

          if(name.toLowerCase().includes(e.target.value.toLowerCase())){
            component.classList.remove('hidden');
          } else {
            component.classList.add('hidden');
          }
        });
        // search_loading.classList.add('hidden');
      }, 500); // Wait for 500 milliseconds before executing the function
    });
  }
});